import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import routesMap from '../../Routes';
import Dots from './v2/Dots';
import { margins, mobileThresholdPixels, colors, Button, Icon, Title1, ContainerBlock, ImgCover }
  from '../../components/Home/v2/StyledComponents';
import Photo1 from '../../assets/photoMan.jpg';
import Assurance3 from '../../assets/assurance-3.jpg';
import icon1 from '../../assets/1.svg';
import icon2 from '../../assets/2.svg';
import icon3 from '../../assets/3.svg';

const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: justify;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 5px 15px;
    max-width: 100%;
  }
`;

const P = styled.p`
  color: ${colors.navy};
  ${props => props.center ? 'text-align: center;' : ''}
`;

const Title2 = styled.h2`
  color: ${colors.navy};
  font-size: 24px;
  font-weight: 300;
  margin: 0px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DotsContainer = styled.div`
  margin: ${margins.m} 0px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 0px 0px 120px;
  align-self: center;

  @media (max-width: ${mobileThresholdPixels}) {
    display: block;
    padding: 0px;
  }
`;

const HowItWorks3 = () => (
  <ContainerBlock>
    <Title1>Comment Tilli fonctionne ?</Title1>
    <Column>
      <DotsContainer><Dots big /></DotsContainer>
      <Row>
        <Icon src={icon1} alt="Retoucherie Paris" noMarginLeft />
        <Title2>La prise de RDV</Title2>
      </Row>
      <P>
        Vous commandez votre retouche sur <Link to={routesMap.Step1.url}>notre site</Link>
        {' ou sur l’application mobile Tilli. '}
        Selon les types de retouches nécessaires, les différentes matières à travailler et leur disponibilité,
        Tilli affecte l’un de ses couturiers professionnels.
      </P>
      <P>Vous recevrez un mail de confirmation de RDV pour la date que vous aurez choisie.</P>
      <DotsContainer><Dots big /></DotsContainer>
      <ImgCover src={Assurance3} alt="Retouche express Paris" />
      <DotsContainer><Dots big /></DotsContainer>
      <Row>
        <Icon src={icon2} alt="Top retouche express" noMarginLeft />
        <Title2>Le RDV avec votre Tilliste</Title2>
      </Row>
      <P>{`Votre couturier se déplace directement chez vous à date et heure de votre choix afin de
        prendre vos mesures, épingler et vous conseiller au mieux.
Vous aurez toujours la possibilité d’ajouter ou retirer des vêtements comme bon vous semble pendant
votre rendez-vous avec votre Tilliste.
`}</P>
      <P>
        Vous ne serez débité du montant total de votre commande qu’à la suite de ce premier rendez-vous.
      </P>
      <P>{`
Prévoyez 30 minutes de temps d’échange avec votre couturier qui repartira avec vos vêtements à
retoucher dans son atelier.`}</P>
      <P>{`
Pour toute demande urgente et/ou nécessitant un travail SUR PLACE, merci de nous envoyer un mail à
contact@tilli.fr. Nous nous efforcerons de répondre au mieux à vos demandes exceptionnelles selon
la disponibilité de nos Tillistes.
      `}</P>
      <DotsContainer><Dots big /></DotsContainer>
      <ImgCover src={Photo1} alt="Retoucherie pour cintrer et raccourcir vos manteaux et vestes" />
      <DotsContainer><Dots big /></DotsContainer>
      <Row>
        <Icon src={icon3} alt="Atelier de retouche haut de gamme" noMarginLeft />
        <Title2>Le retour de vos vêtements</Title2>
      </Row>
      <P>{`
Tilli se charge de faire livrer vos vêtements retouchés au lieu convenu en premier
rendez-vous, à date et heure préalablement fixées.
      `}</P>
      <DotsContainer><Dots big /></DotsContainer>
    </Column>
    <ButtonContainer>
      <Link to={routesMap.Step1.url}>
        <Button>{'passer commande'}</Button>
      </Link>
    </ButtonContainer>
  </ContainerBlock>
);

export default HowItWorks3;
